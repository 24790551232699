// MoneyCalculatorWithDailySales.jsx

/* Mini Dokumentace */
/* Na levé straně jsou 3 kalkulačky a na pravé informace s tržbou atd. a výběry a v klady

Levá strana

každá kalkulačka je rozdělena na mince a bankovky, pouze rezervní jsou pouze mince, vše je rozdělené na mince a bankovy, aby bylo jednoduchá změna a lehce se vše poznalo, bohužel se tak všechno muselo dělat vícekrát. Kalkulačky jsou dočásné, data z nich se neukládají a nevytváří se další řádky, pouze se přepisují řádky v databázi.

Coin - Kc
Coin - Eur
ReserveCoin - Kc
Banknote - Kc
Banknote - Eur

Hodnota v kalkulačkách s kterou pracujeme jsem vyřešil tak, že jí upladuju po stisknutí uložit do databáze, pouze poprvé po načtení stránky se zobrazí data z databáze, abychom měli výchozí stav. Reset tedy funguje pouze tak ze všechny hodnoty přepíše na 0 a je třeba uložit, aby byli přepsány na 0 hodnoty i v databázi.

U eur je input, který stanovuje exchange ratio, defaultně je nastavené na 24, a neuploaduje se ratio na server.

Pravá strana

Pravá strana pracuje s daty v aktualní den, tedy tržba, datum a všechny hodnoty jsou z aktuálního dne a po půlnoci jsou data z dalšího dne. Pokud v databázi není řádek s dnešním datem, vytvoří se nový a zapíšou se hodnoty, pokud se znova hodnoty uloží upraduje se řádek, dokud nebude další den a vytvoří se další řádek. Takže 1 den = 1 řádek

Datum - pouze zobrazené dnešní datum
Pokladní - zobrazuje se uživatel ze zitadele a ukládá se do databáza jako uživatel který    naposled upravil/uložil data.
Počasí - první sloupec ukazuje data z api.weatherapi.com a druhý sloupec je input, který ukládá hodnotu do databáze. (Ne vždy jsou stupně správně, proto to potvrzujeme manuálně)
Poznámky - poznámky se ukládají do databáze
Tržba - tržba se získává z databaze POS a při uložení se ukládá do looteaDB databaze
Karta, Wolt, Bolt - první sloupec ukazuje stav v POS databázi druhý sloupec s input se po stisknutí uložit ukládá do looteadB databaze
Hotovost - první sloupec ukazuje POS stav a druhý sloupec se vypočítává z *********************************** DOPLNIT
Hotovost v pokladně - první sloupec ukazuje hodnotu spočítanou ze včerejší hotovosti v pokladně + hotovost v pokladne podle POS databaze + vklad - výběr 
Manko/Přebytek - vypočet je porovnání POS cash,bolt,wolt,card vs. Totalcash(kalkulacky),InputBolt,InputWolt, InputCard (nezapočítavají se vklady a výběry, protože to na manko a přebytek nemá vliv)

Výběry a vklady - výběry a vklady pouzivaji ID aktuálního řádku a jiný table v datbazi, tak jsou s pojeny s denní uzávěrkou. Jakmile se přídá řádek tak se přídá i v databázi, pokud se odstraní tak se odstraní i v databázi, není třeba ukládát.

Tlačítko uložit drží handlesave, a je alfaomega zapisovaní frontendu do looteaDB databaze.

Veškerý kód je v jednom souboru, protože mi to příjde přehlednější a jednodušeji se s tím pracuje, když jsem to měl rozdělené do 2 a více kódů a komponenty vedle ztrácel jsem se.

Po roce kodováni je to můj masterpiece tak nebuď píča až se na to budeš dívat za další rok.
*/

import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "oidc-react";
import "./dailyClosure.scss";
import { Card, Tabs, Button, Select, Table, Input, notification } from "antd";

import callApi from "../../ApiCaller";
import { useSocket } from "../../SocketProvider";

function MoneyCalculatorWithDailySales() {
  const [isLoading, setIsLoading] = useState(true);
  const [euroExchangeRate, setEuroExchangeRate] = useState(24);
  const [coinCountsKc, setCoinCountsKc] = useState({
    50: { count: 0, total: 0 },
    20: { count: 0, total: 0 },
    10: { count: 0, total: 0 },
    5: { count: 0, total: 0 },
    2: { count: 0, total: 0 },
    1: { count: 0, total: 0 },
  });
  const [banknoteCountsKc, setBanknoteCountsKc] = useState({
    5000: { count: 0, total: 0 },
    2000: { count: 0, total: 0 },
    1000: { count: 0, total: 0 },
    500: { count: 0, total: 0 },
    200: { count: 0, total: 0 },
    100: { count: 0, total: 0 },
  });
  const [coinCountsEur, setCoinCountsEur] = useState({
    2.0: { count: 0, total: 0 },
    1.0: { count: 0, total: 0 },
    0.5: { count: 0, total: 0 },
    0.2: { count: 0, total: 0 },
    0.1: { count: 0, total: 0 },
    0.05: { count: 0, total: 0 },
    0.02: { count: 0, total: 0 },
    0.01: { count: 0, total: 0 },
  });
  const [banknoteCountsEur, setBanknoteCountsEur] = useState({
    500: { count: 0, total: 0 },
    200: { count: 0, total: 0 },
    100: { count: 0, total: 0 },
    50: { count: 0, total: 0 },
    20: { count: 0, total: 0 },
    10: { count: 0, total: 0 },
    5: { count: 0, total: 0 },
  });
  const [reserveCoinCountsKc, setReserveCoinCountsKc] = useState({
    50: { count: 0, total: 0 },
    20: { count: 0, total: 0 },
    10: { count: 0, total: 0 },
    5: { count: 0, total: 0 },
    2: { count: 0, total: 0 },
    1: { count: 0, total: 0 },
  });
  const [yesterdayTotalCash, setyesterdayTotalCash] = useState(0);
  const [totalCash, setTotalCash] = useState(0);
  const [posCash, setPosCash] = useState(0);
  const [dailySales, setDailySales] = useState(0);
  const [inputBolt, setInputBolt] = useState(0);
  const [posBolt, setPosBolt] = useState(0);
  const [inputWolt, setInputWolt] = useState(0);
  const [posWolt, setPosWolt] = useState(0);
  const [inputFoodora, setInputFoodora] = useState(0);
  const [posFoodora, setPosFoodora] = useState(0);
  const [inputNesnezeno, setInputNesnezeno] = useState(0);
  const [posNesnezeno, setPosNesnezeno] = useState(0);
  const [inputCard, setInputCard] = useState(0);
  const [posCard, setPosCard] = useState(0);
  const [dailyClosureId, setDailyClosureId] = useState(null);
  const [newEntry, setNewEntry] = useState({
    type: "Výběr",
    amount: "",
    description: "",
  });
  const [cashInOutEntries, setCashInOutEntries] = useState([]);
  const [totalCashInOut, setTotalCashInOut] = useState(0);
  const [inputWeather, setInputWeather] = useState("");
  const [inputComment, setInputComment] = useState("");
  const [weatherData, setWeatherData] = useState("");
  const socket = useSocket();
  const auth = useAuth();
  const posSale =
    posCash + posWolt + posFoodora + parseFloat(posCard) + posNesnezeno; // + posBolt

  const cashRegisterSale =
    totalCash + inputWolt + inputFoodora + inputCard + inputNesnezeno; // + inputBolt
  const todaydaywithoutadjust = new Date().toISOString().split("T")[0];
  const todayDate = todaydaywithoutadjust.split("-").reverse().join(". ");

  // Funkce pro výpočet celkové sumy
  const calculateTotal = (counts) =>
    Object.entries(counts).reduce(
      (total, [value, item]) => total + parseFloat(value) * item.count,
      0
    );

  const totalCoinsKc = calculateTotal(coinCountsKc);
  const totalBanknotesKc = calculateTotal(banknoteCountsKc);
  const totalReserveCoinsKc = calculateTotal(reserveCoinCountsKc);
  const totalSumKc = totalCoinsKc + totalBanknotesKc + totalReserveCoinsKc;
  const totalCoinsEur = calculateTotal(coinCountsEur);
  const totalBanknotesEur = calculateTotal(banknoteCountsEur);
  const totalSumEur = totalCoinsEur + totalBanknotesEur;
  const totalSumEurOnKc = totalSumEur * euroExchangeRate;
  const totalSumKcPlusEur = Math.round(totalSumEurOnKc + totalSumKc);
  const cardDiff = Math.round((inputCard - posCard) * 100) / 100; // rozdíl mezi terminálem a kartou v kase

  const sortedCoinsEurValues = Object.keys(coinCountsEur).sort((a, b) => b - a);

  const sortedCoinsKcValues = Object.keys(coinCountsKc)
    .map(parseFloat)
    .sort((a, b) => b - a);

  const sortedReserveCoinsKcValues = Object.keys(reserveCoinCountsKc)
    .map(parseFloat)
    .sort((a, b) => b - a);
  const sortedBankNotesKcValues = Object.keys(banknoteCountsKc)
    .map(parseFloat)
    .sort((a, b) => b - a);

  const sortedBankNotesEurKcValues = Object.keys(banknoteCountsEur)
    .map(parseFloat)
    .sort((a, b) => b - a);

  useEffect(() => {
    callApi("daily-sales").then((data) => {
      const today = todaydaywithoutadjust;

      const sumArray = (array) => array.reduce((acc, item) => acc + item, 0);

      const cash = sumArray(
        data
          .filter(
            (item) =>
              item.Date &&
              item.Date.startsWith(today) &&
              item.PaymentType === "Cash"
          )
          .map((item) => item.Total)
      );

      const card = sumArray(
        data
          .filter(
            (item) =>
              item.Date &&
              item.Date.startsWith(today) &&
              item.PaymentType === "Card - Comgate"
          )
          .map((item) => item.Total)
      );

      const wolt = sumArray(
        data
          .filter(
            (item) =>
              item.Date &&
              item.Date.startsWith(today) &&
              item.PaymentType === "WOLT"
          )
          .map((item) => item.Total)
      );

      const foodora = sumArray(
        data
          .filter(
            (item) =>
              item.Date &&
              item.Date.startsWith(today) &&
              item.PaymentType === "FOODORA"
          )
          .map((item) => item.Total)
      );

      const bolt = sumArray(
        data
          .filter(
            (item) =>
              item.Date &&
              item.Date.startsWith(today) &&
              item.PaymentType === "BOLT"
          )
          .map((item) => item.Total)
      );

      const nesnezeno = sumArray(
        data
          .filter(
            (item) =>
              item.Date &&
              item.Date.startsWith(today) &&
              item.PaymentType === "Nesnězeno"
          )
          .map((item) => item.Total)
      );
      setPosCash(cash);
      setPosCard(card);
      setPosWolt(wolt);
      setPosFoodora(foodora);
      setPosBolt(bolt);
      setPosNesnezeno(nesnezeno);
      const dailyTotal = cash + card + wolt + foodora + bolt + nesnezeno;
      setDailySales(Number(dailyTotal.toFixed(2)));
      setIsLoading(false);
    });

    // weather
    const fetchData = async () => {
      try {
        const currentWeatherResponse = await fetch(
          "https://api.weatherapi.com/v1/current.json?key=076d548f206a4884bf0131552230112&q=Praha&aqi=yes&lang=cs"
        );
        if (!currentWeatherResponse.ok) {
          throw new Error("HTTP Error " + currentWeatherResponse.status);
        }
        const currentWeatherData = await currentWeatherResponse.json();
        setWeatherData(currentWeatherData.current.temp_c);
      } catch (error) {
        console.error("Chyba při získávání dat o počasí:", error);
      }
    };

    fetchData();
    loadYesterdayTotalCash();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    // Kontrola shody hodnot
    const valuesMatch =
      inputWolt === posWolt &&
      inputFoodora === posFoodora &&
      inputBolt === posBolt &&
      inputNesnezeno === posNesnezeno &&
      inputCard === Math.round(posCard * 10) / 10; // zaokrouhleny, aby se to srovnalo s kartou, občas chodí z posky divne cisla jako treba 0.5001, protože jsou tam spatne resene slevy z Aronia

    if (!valuesMatch) {
      // Zobrazí chybovou notifikaci
      notification.error({
        message: "Hodnoty se neshodují",
        description: "Prosím překontroluj Kartu, Wolt, atd. a zkus to znovu.",
        placement: "top",
        duration: 5,
      });
    }
    // Uložení dat
    Object.entries(reserveCoinCountsKc).forEach(
      ([denomination, { count, total }]) => {
        const updateData = { denomination, count, total };
        socket.emit("update_reserve_coin_counts_kc", updateData);
      }
    );
    Object.entries(coinCountsKc).forEach(([denomination, { count, total }]) => {
      const updateData = { denomination, count, total };
      socket.emit("update_coin_counts_kc", updateData);
    });
    Object.entries(banknoteCountsKc).forEach(
      ([denomination, { count, total }]) => {
        const updateData = { denomination, count, total };
        socket.emit("update_banknote_counts_kc", updateData);
      }
    );
    Object.entries(coinCountsEur).forEach(
      ([denomination, { count, total }]) => {
        const updateData = { denomination, count, total };
        socket.emit("update_coin_counts_eur", updateData);
      }
    );
    Object.entries(banknoteCountsEur).forEach(
      ([denomination, { count, total }]) => {
        const updateData = { denomination, count, total };
        socket.emit("update_banknote_counts_eur", updateData);
      }
    );

    const payload = {
      Datum: new Date().toISOString().split("T")[0],
      Pokladnik:
        `${auth.userData?.profile?.given_name} ${auth.userData?.profile?.family_name}` ||
        "undefined user",
      Trzba: dailySales,
      Poznamky: inputComment,
      Pocasi: inputWeather,
      Karta: inputCard,
      Wolt: inputWolt,
      Foodora: inputFoodora,
      Bolt: inputBolt,
      Nesnezeno: inputNesnezeno,
      Hotovost: totalCash,
      Hotovost_v_pokladne: totalSumKcPlusEur,
      Manko_Prebytek: totalStatus,
    };
    // Odeslání dat na server přes WebSocket
    socket.emit("save_daily_record", payload);

    // Poslouchejte potvrzení od serveru
    if (valuesMatch) {
      socket.once("daily_record_saved", (message) => {
        if (message.success) {
          // Zobrazí úspěšnou notifikaci
          notification.success({
            message: "Data uložena",
            description: "Vaše data byla úspěšně uložena do databáze.",
            placement: "bottomRight",
            duration: 2,
          });
        } else {
          // Zobrazí chybovou notifikaci
          notification.error({
            message: "Chyba při ukládání",
            description:
              "Nepodařilo se uložit vaše data. Zkuste to prosím znovu.",
            placement: "bottomRight",
            duration: 2,
          });
        }
      });
    }
  };

  const fetchCashInOutEntries = () => {
    socket.emit("get_cash_in_out_records");
  };

  const calculateAndUpdateTotalCash = useCallback(
    (cashInOutData) => {
      const totalCashInOut = cashInOutData.reduce((acc, record) => {
        const amount = parseFloat(record.Castka);
        return record.TypOperace === "➕" ? acc + amount : acc - amount;
      }, 0);
      // Update the totalCashInOut state
      setTotalCashInOut(totalCashInOut);

      // Calculate the new total cash
      const newTotalCash =
        totalSumKcPlusEur - (yesterdayTotalCash + totalCashInOut);

      // Update the state for total cash
      setTotalCash(newTotalCash);
    },
    [yesterdayTotalCash, totalSumKcPlusEur]
  );

  useEffect(() => {
    const loadDailyRecord = () => {
      const today = new Date().toISOString().split("T")[0];
      // Pošle požadavek na server pro načtení dat pro aktuální den
      socket.emit("load_daily_record", { Datum: today });
    };

    // Přijetí dat od serveru
    socket.on("daily_record_data", (data) => {
      const serverDate =
        data && data.Datum
          ? new Date(data.Datum).toDateString()
          : new Date().toDateString();
      const today = new Date().toDateString();

      if (data && serverDate === today) {
        setInputComment(data.Poznamky || "");
        setInputWeather(parseFloat(data.Pocasi) || "");
        setInputCard(parseFloat(data.Karta) || 0);
        setInputWolt(parseFloat(data.Wolt) || 0);
        setInputFoodora(parseFloat(data.Foodora) || 0);
        setInputBolt(parseFloat(data.Bolt) || 0);
        setInputNesnezeno(parseFloat(data.Nesnezeno) || 0);
        setDailyClosureId(data.ID);
      }
    });
    // Požádá server o data
    socket.emit("get_coin_counts_kc");
    socket.emit("get_reserve_coin_counts_kc");
    socket.emit("get_banknote_counts_kc");
    socket.emit("get_coin_counts_eur");
    socket.emit("get_banknote_counts_eur");

    // Přijímá data od serveru
    socket.on("coin_counts_kc_data", (data) => {
      const transformedData = data.reduce(
        (acc, { denomination, count, total }) => {
          acc[denomination] = { count, total };
          return acc;
        },
        {}
      );
      setCoinCountsKc(transformedData);
    });
    socket.on("reserve_coin_counts_kc_data", (data) => {
      const transformedData = data.reduce(
        (acc, { denomination, count, total }) => {
          acc[denomination] = { count, total };
          return acc;
        },
        {}
      );
      setReserveCoinCountsKc(transformedData);
    });
    socket.on("banknote_counts_kc_data", (data) => {
      // Transformace a uložení dat
      const transformedData = data.reduce(
        (acc, { denomination, count, total }) => {
          acc[denomination] = { count, total };
          return acc;
        },
        {}
      );
      setBanknoteCountsKc(transformedData);
    });
    socket.on("coin_counts_eur_data", (data) => {
      const transformedData = data.reduce(
        (acc, { denomination, count, total }) => {
          acc[denomination] = { count, total };
          return acc;
        },
        {}
      );
      setCoinCountsEur(transformedData);
    });
    socket.on("banknote_counts_eur_data", (data) => {
      const transformedData = data.reduce(
        (acc, { denomination, count, total }) => {
          acc[denomination] = { count, total };
          return acc;
        },
        {}
      );
      setBanknoteCountsEur(transformedData);
    });
    socket.on("cash_in_out_records_data", (data) => {
      const modifiedData = data.map((entry) => ({
        ...entry,
        TypOperace:
          entry.TypOperace === "Vklad"
            ? "➕"
            : entry.TypOperace === "Vyber"
            ? "➖"
            : entry.TypOperace,
      }));
      setCashInOutEntries(modifiedData);
      calculateAndUpdateTotalCash(data);
    });
    socket.on("cash_in_out_record_deleted", (confirmation) => {
      fetchCashInOutEntries();
    });
    // Updatuje data z frontendu
    socket.on("coin_counts_kc_updated", (data) => {
      setCoinCountsKc((prevState) => ({
        ...prevState,
        [data.denomination]: { count: data.count, total: data.total },
      }));
    });
    socket.on("reserve_coin_counts_kc_updated", (data) => {
      setReserveCoinCountsKc((prevState) => ({
        ...prevState,
        [data.denomination]: { count: data.count, total: data.total },
      }));
    });
    socket.on("banknote_counts_kc_updated", (data) => {
      setBanknoteCountsKc((prevState) => ({
        ...prevState,
        [data.denomination]: { count: data.count, total: data.total },
      }));
    });

    fetchCashInOutEntries();
    loadDailyRecord();

    return () => {
      socket.off("daily_record_data");
      socket.off("coin_counts_kc_data");
      socket.off("coin_counts_kc_updated");
      socket.off("banknote_counts_kc_data");
      socket.off("banknote_counts_kc_updated");
      socket.off("update_reserve_coin_counts_kc");
      socket.off("reserve_coin_counts_kc_data");
      socket.off("reserve_coin_counts_kc_updated");
      socket.off("coin_counts_eur_data");
      socket.off("banknote_counts_eur_data");
      socket.off("cash_in_out_records_data");
      socket.off("cash_in_out_record_added");
      socket.off("cash_in_out_record_deleted");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calculateAndUpdateTotalCash(cashInOutEntries);
  }, [cashInOutEntries, calculateAndUpdateTotalCash]);
  const totalStatus =
    !isNaN(cashRegisterSale) && !isNaN(posSale)
      ? Math.round(cashRegisterSale - posSale)
      : "Neplatná hodnota";

  const normalizeKey = (value) => Number(value).toFixed(2);

  const getCashStatusColor = (totalStatus) => {
    if (totalStatus < 0) return "rgb(255, 24, 24)";
    return "rgb(4, 255, 0)";
  };

  const loadYesterdayTotalCash = () => {
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .split("T")[0];

    // Pošle požadavek na server pro načtení 'Hotovost' z předchozího dne
    socket.emit("load_daily_record", { Datum: yesterday });

    // Přijetí dat od serveru
    socket.on("daily_record_data", (data) => {
      const serverDate =
        data && data.Datum
          ? new Date(data.Datum).toDateString()
          : new Date().toDateString();
      const yesterdayDate = new Date(yesterday).toDateString();

      if (data && serverDate === yesterdayDate) {
        setyesterdayTotalCash(parseFloat(data.Hotovost_v_pokladne));
      }
    });

    return () => {
      socket.off("daily_record_data");
    };
  };

  // Funkce pro změnu kurzu eura
  const handleEuroExchangeRateChange = (value) => {
    // Pokud je hodnota prázdná, není platné číslo nebo je menší nebo rovno 0, použije se výchozí kurz
    const newRate = parseFloat(value);
    if (isNaN(newRate) || newRate <= 0) {
      setEuroExchangeRate(24); // Výchozí hodnota kurzu eura
    } else {
      setEuroExchangeRate(newRate);
    }
  };

  // Funkce pro formátování hodnot
  const formatValueWithEuro = (value) =>
    `${parseFloat(value).toFixed(2).toLocaleString("de-DE")} €`;
  const formatValueWithKc = (value) =>
    `${parseFloat(value).toLocaleString("cs-CZ")} Kč`;

  // Funkce pro změnu počtu mincí
  const handleCoinCountChangeEur = (value, count) => {
    const key = normalizeKey(value);
    if (!isNaN(count)) {
      const total = value * count;
      setCoinCountsEur({ ...coinCountsEur, [key]: { count, total } });
    } else {
      setCoinCountsEur({ ...coinCountsEur, [key]: { count: 0, total: 0 } });
    }
  };
  const handleCoinCountChangeKc = (value, count) => {
    if (!isNaN(count)) {
      const total = value * count;
      setCoinCountsKc({ ...coinCountsKc, [value]: { count, total } });
    } else {
      setCoinCountsKc({ ...coinCountsKc, [value]: { count: 0, total: 0 } });
    }
  };
  const handleReserveCoinCountChangeKc = (denomination, count) => {
    if (!isNaN(count)) {
      const total = denomination * count;
      // Aktualizujte stav v aplikaci
      setReserveCoinCountsKc({
        ...reserveCoinCountsKc,
        [denomination]: { count, total },
      });
    } else {
      setReserveCoinCountsKc({
        ...reserveCoinCountsKc,
        [denomination]: { count: 0, total: 0 },
      });
    }
  };

  // Funkce pro změnu počtu bankovek
  const handleBanknoteCountChangeEur = (value, count) => {
    if (!isNaN(count)) {
      const total = value * count;
      setBanknoteCountsEur({ ...banknoteCountsEur, [value]: { count, total } });
    } else {
      setBanknoteCountsEur({
        ...banknoteCountsEur,
        [value]: { count: 0, total: 0 },
      });
    }
  };
  const handleBanknoteCountChangeKc = (value, count) => {
    if (!isNaN(count) && count >= 0) {
      const total = value * count;
      setBanknoteCountsKc({ ...banknoteCountsKc, [value]: { count, total } });
    } else {
      setBanknoteCountsKc({
        ...banknoteCountsKc,
        [value]: { count: 0, total: 0 },
      });
    }
  };

  // funkce na pridani +1 a -1
  const incrementCoinCountKc = (value) => {
    const count = coinCountsKc[value].count + 1;
    const total = value * count;
    setCoinCountsKc({ ...coinCountsKc, [value]: { count, total } });
  };
  const decrementCoinCountKc = (value) => {
    const count = Math.max(0, coinCountsKc[value].count - 1); // Zabraňuje negativním hodnotám
    const total = value * count;
    setCoinCountsKc({ ...coinCountsKc, [value]: { count, total } });
  };
  const incrementBanknoteCountKc = (value) => {
    const currentCount = banknoteCountsKc[value].count;
    const newCount = currentCount + 1;
    const newTotal = value * newCount;
    setBanknoteCountsKc({
      ...banknoteCountsKc,
      [value]: { count: newCount, total: newTotal },
    });
  };
  const decrementBanknoteCountKc = (value) => {
    const currentCount = banknoteCountsKc[value].count;
    const newCount = Math.max(0, currentCount - 1);
    const newTotal = value * newCount;
    setBanknoteCountsKc({
      ...banknoteCountsKc,
      [value]: { count: newCount, total: newTotal },
    });
  };
  const incrementReserveCoinCountKc = (denomination) => {
    const newCount = reserveCoinCountsKc[denomination].count + 1;
    const newTotal = newCount * denomination;

    // Optimistická aktualizace UI
    setReserveCoinCountsKc((prevState) => ({
      ...prevState,
      [denomination]: { count: newCount, total: newTotal },
    }));
  };
  const decrementReserveCoinCountKc = (denomination) => {
    const newCount = Math.max(0, reserveCoinCountsKc[denomination].count - 1);
    const newTotal = newCount * denomination;

    // Optimistická aktualizace UI
    setReserveCoinCountsKc((prevState) => ({
      ...prevState,
      [denomination]: { count: newCount, total: newTotal },
    }));
  };
  const incrementCoinCountEur = (value) => {
    const key = normalizeKey(value);
    const currentCount = coinCountsEur[key]?.count || 0;
    const newCount = currentCount + 1;
    const newTotal = value * newCount;
    setCoinCountsEur({
      ...coinCountsEur,
      [key]: { count: newCount, total: newTotal },
    });
  };
  const decrementCoinCountEur = (value) => {
    const key = normalizeKey(value);
    const currentCount = coinCountsEur[key]?.count || 0;
    const newCount = Math.max(0, currentCount - 1); // Zabraňuje negativním hodnotám
    const newTotal = value * newCount;
    setCoinCountsEur({
      ...coinCountsEur,
      [key]: { count: newCount, total: newTotal },
    });
  };

  const incrementBanknoteCountEur = (value) => {
    const count = banknoteCountsEur[value].count + 1;
    const total = value * count;
    setBanknoteCountsEur({ ...banknoteCountsEur, [value]: { count, total } });
  };
  const decrementBanknoteCountEur = (value) => {
    const count = Math.max(0, banknoteCountsEur[value].count - 1);
    const total = value * count;
    setBanknoteCountsEur({ ...banknoteCountsEur, [value]: { count, total } });
  };

  // funkce na vynulování
  const resetCoinCountKc = (value) => {
    setCoinCountsKc({ ...coinCountsKc, [value]: { count: 0, total: 0 } });
  };
  const resetBanknoteCountKc = (value) => {
    setBanknoteCountsKc({
      ...banknoteCountsKc,
      [value]: { count: 0, total: 0 },
    });
  };
  const resetReserveCoinCountKc = (value) => {
    setReserveCoinCountsKc({
      ...reserveCoinCountsKc,
      [value]: { count: 0, total: 0 },
    });
  };
  const resetCoinCountEur = (value) => {
    setCoinCountsEur({ ...coinCountsEur, [value]: { count: 0, total: 0 } });
  };
  const resetBanknoteCountEur = (value) => {
    setBanknoteCountsEur({
      ...banknoteCountsEur,
      [value]: { count: 0, total: 0 },
    });
  };
  const resetAllKc = () => {
    const resetCoinsKc = Object.fromEntries(
      Object.keys(coinCountsKc).map((key) => [key, { count: 0, total: 0 }])
    );
    setCoinCountsKc(resetCoinsKc);

    const resetBanknotesKc = Object.fromEntries(
      Object.keys(banknoteCountsKc).map((key) => [key, { count: 0, total: 0 }])
    );
    setBanknoteCountsKc(resetBanknotesKc);
  };
  const resetReserveAllKc = () => {
    const resetReserveCoinsKc = Object.fromEntries(
      Object.keys(reserveCoinCountsKc).map((key) => [
        key,
        { count: 0, total: 0 },
      ])
    );
    setReserveCoinCountsKc(resetReserveCoinsKc);
  };
  const resetAllEur = () => {
    const resetCoinsEur = Object.fromEntries(
      Object.keys(coinCountsEur).map((key) => [key, { count: 0, total: 0 }])
    );
    setCoinCountsEur(resetCoinsEur);

    const resetBanknotesEur = Object.fromEntries(
      Object.keys(banknoteCountsEur).map((key) => [key, { count: 0, total: 0 }])
    );
    setBanknoteCountsEur(resetBanknotesEur);
  };

  const handleAddCashInOutEntry = () => {
    // odeslani cashinout
    if (newEntry.amount && newEntry.description && dailyClosureId) {
      socket.emit("add_cash_in_out_record", {
        DailycashclosureID: dailyClosureId,
        TypOperace: newEntry.type,
        Castka: newEntry.amount,
        Popis: newEntry.description,
      });
      setNewEntry({ type: "Výběr", amount: "", description: "" });
      fetchCashInOutEntries();
    } else {
      alert("Vyplňte všechna pole a ujistěte se, že máte platné ID uzávěrky!");
    }
  };

  const handleRemoveCashInOutEntry = (recordID) => {
    socket.emit("delete_cash_in_out_record", recordID);
  };

  const tabItems = [
    {
      label: "České koruny (Kč)",
      key: "1",
      children: (
        <div className="container">
          <table className="table">
            <thead>
              <tr>
                <th className="faceValue">Hodnota</th>
                <th className="pieces">Počet kusů</th>
                <th className="total">Celkem</th>
                <th className="deleteAllRows" onClick={resetAllKc}>
                  <span>❌</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {sortedBankNotesKcValues.map((value) => (
                <tr key={value}>
                  <td>{formatValueWithKc(value)}</td>
                  <td className="plusMinus">
                    <span onClick={() => decrementBanknoteCountKc(value)}>
                      ➖
                    </span>
                    <Input
                      type="number"
                      min="0"
                      value={banknoteCountsKc[value].count}
                      style={{
                        height: "40px",
                        width: "80px",
                        textAlign: "center",
                      }}
                      onChange={(e) =>
                        handleBanknoteCountChangeKc(
                          value,
                          parseInt(e.target.value)
                        )
                      }
                    />
                    <span onClick={() => incrementBanknoteCountKc(value)}>
                      ➕
                    </span>
                  </td>
                  <td>{formatValueWithKc(banknoteCountsKc[value].total)}</td>
                  <td className="resetColumn">
                    <span onClick={() => resetBanknoteCountKc(value)}>❌</span>
                  </td>
                </tr>
              ))}
            </tbody>
            <tbody>
              {sortedCoinsKcValues.map((value) => (
                <tr key={value}>
                  <td>{formatValueWithKc(value)}</td>
                  <td className="plusMinus">
                    <span onClick={() => decrementCoinCountKc(value)}>➖</span>
                    <Input
                      type="number"
                      min="0"
                      value={coinCountsKc[value].count}
                      style={{
                        height: "40px",
                        width: "80px",
                        textAlign: "center",
                      }}
                      onChange={(e) =>
                        handleCoinCountChangeKc(value, parseInt(e.target.value))
                      }
                    />
                    <span onClick={() => incrementCoinCountKc(value)}>➕</span>
                  </td>
                  <td>{formatValueWithKc(coinCountsKc[value].total)}</td>
                  <td className="resetColumn">
                    {" "}
                    <span onClick={() => resetCoinCountKc(value)}>❌</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ),
    },
    {
      label: "Eura (EUR)",
      key: "2",
      children: (
        <div className="container">
          <table className="table">
            <thead>
              <tr>
                <th className="faceValue">Hodnota</th>
                <th className="pieces">Počet kusů</th>
                <th className="total">Celkem</th>
                <th className="deleteAllRows" onClick={resetAllEur}>
                  <span>❌</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedBankNotesEurKcValues.map((value) => (
                <tr key={value}>
                  <td>{formatValueWithEuro(value)}</td>
                  <td className="plusMinus">
                    <span onClick={() => decrementBanknoteCountEur(value)}>
                      ➖
                    </span>
                    <Input
                      type="number"
                      min="0"
                      value={banknoteCountsEur[value].count}
                      style={{
                        height: "40px",
                        width: "80px",
                        textAlign: "center",
                      }}
                      onChange={(e) =>
                        handleBanknoteCountChangeEur(
                          value,
                          parseInt(e.target.value)
                        )
                      }
                    />
                    <span onClick={() => incrementBanknoteCountEur(value)}>
                      ➕
                    </span>
                  </td>
                  <td>{formatValueWithEuro(banknoteCountsEur[value].total)}</td>
                  <td className="resetColumn">
                    {" "}
                    <span onClick={() => resetBanknoteCountEur(value)}>❌</span>
                  </td>
                </tr>
              ))}
            </tbody>
            <tbody>
              {sortedCoinsEurValues.map((value) => (
                <tr key={value}>
                  <td>{formatValueWithEuro(value)}</td>
                  <td className="plusMinus">
                    <span onClick={() => decrementCoinCountEur(value)}>➖</span>
                    <Input
                      type="number"
                      min="0"
                      value={coinCountsEur[normalizeKey(value)]?.count || 0}
                      style={{
                        height: "40px",
                        width: "80px",
                        textAlign: "center",
                      }}
                      onChange={(e) =>
                        handleCoinCountChangeEur(
                          value,
                          parseInt(e.target.value)
                        )
                      }
                    />
                    <span onClick={() => incrementCoinCountEur(value)}>➕</span>
                  </td>
                  <td>
                    {formatValueWithEuro(
                      coinCountsEur[normalizeKey(value)]?.total || 0
                    )}
                  </td>

                  <td className="resetColumn">
                    {" "}
                    <span onClick={() => resetCoinCountEur(value)}>❌</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ),
    },
    {
      label: "Záložní pokladna (Kč mince)",
      key: "3",
      children: (
        <div className="container">
          <table className="table">
            <thead>
              <tr>
                <th className="faceValue">Hodnota</th>
                <th className="pieces">Počet kusů</th>
                <th className="total">Celkem</th>
                <th className="deleteAllRows" onClick={resetReserveAllKc}>
                  <span>❌</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedReserveCoinsKcValues.map((value) => (
                <tr key={value}>
                  <td>{formatValueWithKc(value)}</td>
                  <td className="plusMinus">
                    <span onClick={() => decrementReserveCoinCountKc(value)}>
                      ➖
                    </span>
                    <Input
                      type="number"
                      min="0"
                      value={reserveCoinCountsKc[value].count}
                      style={{
                        height: "40px",
                        width: "80px",
                        textAlign: "center",
                      }}
                      onChange={(e) =>
                        handleReserveCoinCountChangeKc(
                          value,
                          parseInt(e.target.value)
                        )
                      }
                    />
                    <span onClick={() => incrementReserveCoinCountKc(value)}>
                      ➕
                    </span>
                  </td>
                  <td>{formatValueWithKc(reserveCoinCountsKc[value].total)}</td>
                  <td className="resetColumn">
                    {" "}
                    <span onClick={() => resetReserveCoinCountKc(value)}>
                      ❌
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ),
    },
  ];

  if (isLoading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="containerUpper">
        <div className="calculatorH1">
          <h1>Dnešní tržba</h1>
        </div>

        <div className="containerWrap">
          {/* leftside*/}
          <div className="dailyStatus">
            <div className="containerLower">
              <div className="leftContainer">
                <Card className="calculatorCard" bordered={false}>
                  <Tabs defaultActiveKey="1" type="card" items={tabItems} />
                </Card>
                <div className="tableResult">
                  <div className="totalKc">
                    <div className="left">
                      <span>Mince:</span>
                      <span>Bankovky:</span>
                      <span>Rezervní Mince:</span>
                      <h3>Celkem:</h3>
                    </div>
                    <div className="right">
                      <span>{formatValueWithKc(totalCoinsKc)}</span>
                      <span>{formatValueWithKc(totalBanknotesKc)}</span>
                      <span>{formatValueWithKc(totalReserveCoinsKc)}</span>

                      <h3> {formatValueWithKc(totalSumKc)}</h3>
                    </div>
                  </div>

                  <div className="totalEur">
                    <div className="left">
                      <span>Mince:</span>
                      <span>Bankovky:</span>
                      <h3>Celkem:</h3>
                    </div>
                    <div className="right">
                      <span>{formatValueWithEuro(totalCoinsEur)}</span>
                      <span> {formatValueWithEuro(totalBanknotesEur)}</span>
                      <h3> {formatValueWithEuro(totalSumEur.toFixed(2))}</h3>
                    </div>
                  </div>

                  <div className="kurzEUR">
                    <div className="left">
                      <span>Kurz EUR </span>
                    </div>
                    <div className="right">
                      <Input
                        type="number"
                        min="0"
                        value={euroExchangeRate}
                        onChange={(e) =>
                          handleEuroExchangeRateChange(e.target.value)
                        }
                        style={{
                          height: "30px",
                          width: "45px",
                          textAlign: "center",
                        }}
                      />
                    </div>
                  </div>
                  <div className="celkovaSuma">
                    <span>
                      Celková hotovost v pokladně:{" "}
                      <strong>{formatValueWithKc(totalSumKcPlusEur)}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* rightside */}
          <div className="inputValue">
            <div className="date">
              <span>Datum</span>
              <span>{todayDate}</span>
            </div>
            <div className="cashier">
              <h4>Pokladní</h4>
              <span>
                {auth.userData?.profile?.given_name}{" "}
                {auth.userData?.profile?.family_name}
              </span>
            </div>
            <div
              className="mezera"
              style={{
                borderTop: "0.5px solid rgba(128, 128, 128, 0.5)",
                marginBottom: "10px",
              }}
            />
            <div className="weather">
              <span className="left">Počasí</span>
              <span className="midSpan">{weatherData} °C</span>
              <div className="input">
                <Input
                  type="number"
                  value={inputWeather}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    setInputWeather(isNaN(value) ? "" : value);
                  }}
                  placeholder="°C?"
                />
                <span>°C</span>
              </div>
            </div>
            <div className="notes">
              <span className="left">Poznámky</span>
              <div className="input">
                <Input
                  className="noteInput"
                  type="text"
                  value={inputComment}
                  onChange={(e) => setInputComment(e.target.value)}
                  placeholder="Jaký byl dnes den v práci?"
                />
              </div>
            </div>
            <div
              className="mezera"
              style={{
                borderTop: "0.5px solid rgba(128, 128, 128, 0.5)",
                marginBottom: "10px",
              }}
            />
            <div className="dailysale">
              <span className="left">Tržba</span>
              <span> {formatValueWithKc(dailySales)}</span>{" "}
            </div>
            <div className="card">
              <span className="left">Karta</span>
              <span className="midSpan">
                {" "}
                {formatValueWithKc(posCard)}
              </span>{" "}
              <div className="input">
                {cardDiff !== 0 && <p className="cardDiff">{cardDiff}</p>}
                <Input
                  type="number"
                  value={inputCard}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value.replace(",", "."));
                    setInputCard(isNaN(value) ? 0 : value);
                  }}
                  placeholder="Vložte tržbu na kartě."
                />
                <span>Kč</span>
              </div>
            </div>
            <div className="wolt">
              <span className="left">Wolt</span>
              <span className="midSpan">
                {Array.isArray(posWolt) && posWolt.length === 0
                  ? "0"
                  : formatValueWithKc(posWolt)}
              </span>
              <div className="input">
                <Input
                  type="number"
                  value={inputWolt}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value.replace(",", "."));
                    setInputWolt(isNaN(value) ? 0 : value);
                  }}
                  placeholder="Vložte tržbu na Woltu."
                />
                <span>Kč</span>
              </div>
            </div>
            <div className="foodora">
              <span className="left">Foodora</span>
              <span className="midSpan">
                {Array.isArray(posFoodora) && posFoodora.length === 0
                  ? "0"
                  : formatValueWithKc(posFoodora)}
              </span>
              <div className="input">
                <Input
                  type="number"
                  value={inputFoodora}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value.replace(",", "."));
                    setInputFoodora(isNaN(value) ? 0 : value);
                  }}
                  placeholder="Vložte tržbu na Foodoře."
                />
                <span>Kč</span>
              </div>
            </div>
            {/* <div className="bolt">
              <span className="left">Bolt</span>
              <span>
                {Array.isArray(posBolt) && posBolt.length === 0
                  ? "0"
                  : formatValueWithKc(posBolt)}
              </span>{" "}
              <div className="input">
                <Input
                  type="number"
                  value={inputBolt}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value.replace(",", "."));
                    setInputBolt(isNaN(value) ? 0 : value);
                  }}
                  placeholder="Vložte tržbu na Boltu."
                />
                <span>Kč</span>
              </div>
            </div> */}
            <div className="nesnezeno">
              <span className="left">Nesnězeno</span>
              <span className="midSpan">
                {Array.isArray(posNesnezeno) && posNesnezeno.length === 0
                  ? "0"
                  : formatValueWithKc(posNesnezeno)}
              </span>
              <div className="input">
                <Input
                  type="number"
                  value={inputNesnezeno}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value.replace(",", "."));
                    setInputNesnezeno(isNaN(value) ? 0 : value);
                  }}
                  placeholder="Vložte tržbu na nesnězeno."
                />
                <span>Kč</span>
              </div>
            </div>
            {/* <div className="cash">
              <span className="left">Hotovost</span>
              <span> {formatValueWithKc(posCash)}</span>
              <span className="spanCash">{formatValueWithKc(totalCash)}</span>
            </div> */}
            <div className="cashInPOS">
              <span className="left">Hotovost v pokladně</span>
              <span className="midSpan">
                {formatValueWithKc(
                  yesterdayTotalCash + posCash + totalCashInOut
                )}
              </span>
              <span className="spanCashInPos">
                {formatValueWithKc(totalSumKcPlusEur)}
              </span>
            </div>
            <div className="manko">
              <span className="left">Manko/Přebytek</span>
              <span
                className="spanManko"
                style={{ color: getCashStatusColor(totalStatus) }}
              >
                {formatValueWithKc(totalStatus)}
              </span>
            </div>
            <div
              className="mezera"
              style={{
                borderTop: "0.5px solid rgba(128, 128, 128, 0.5)",
                marginBottom: "10px",
              }}
            />

            <div className="cashInOut">
              <h3>Výběry a vklady</h3>

              <div className="inputs">
                <Select
                  className="select"
                  value={newEntry.type}
                  onChange={(value) =>
                    setNewEntry({ ...newEntry, type: value })
                  }
                >
                  <Select.Option value="Výběr">Výběr ➖</Select.Option>
                  <Select.Option value="Vklad">Vklad ➕</Select.Option>
                </Select>
                <Input
                  className="inputNumber"
                  type="number"
                  placeholder="Částka"
                  value={newEntry.amount}
                  onChange={(e) =>
                    setNewEntry({ ...newEntry, amount: e.target.value })
                  }
                />
                <Input
                  className="inputDescription"
                  placeholder="Popis"
                  value={newEntry.description}
                  onChange={(e) =>
                    setNewEntry({ ...newEntry, description: e.target.value })
                  }
                />
                <Button className="button" onClick={handleAddCashInOutEntry}>
                  Přidat
                </Button>
              </div>

              <Table
                dataSource={cashInOutEntries}
                pagination={false}
                columns={[
                  {
                    title: "Typ",
                    dataIndex: "TypOperace",
                    key: "TypOperace",
                  },
                  {
                    title: "Částka",
                    dataIndex: "Castka",
                    key: "Castka",
                  },
                  {
                    title: "Popis",
                    dataIndex: "Popis",
                    key: "Popis",
                  },
                  {
                    title: "Akce",
                    key: "action",
                    render: (text, record, index) => (
                      <Button
                        onClick={() => handleRemoveCashInOutEntry(record.ID)}
                      >
                        ❌
                      </Button>
                    ),
                  },
                ]}
                rowKey="ID"
              />
            </div>
            <div
              className="mezera"
              style={{
                borderTop: "0.5px solid rgba(128, 128, 128, 0.5)",
                marginBottom: "10px",
              }}
            />
            <div className="saveButtonCalculator">
              <Button className="saveCalculator" onClick={handleSave}>
                Uložit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoneyCalculatorWithDailySales;
