import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  DatePicker,
  Space,
  Popover,
  Button,
  message,
  Modal,
} from "antd";
import {
  DownloadOutlined,
  EyeOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import "dayjs/locale/cs";
import locale from "antd/es/date-picker/locale/cs_CZ";
import "./invoiceTable.scss";
import callApi from "../../ApiCaller";

dayjs.extend(isBetween);
dayjs.locale("cs");

const { RangePicker } = DatePicker;

// Helper function to remove diacritics
const removeDiacritics = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const InvoiceTable = ({ data, loading, refreshInvoices }) => {
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  
  useEffect(() => {
    setFilteredData(data);
  }, [data]);
  const [openPopover, setOpenPopover] = useState(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState(null);
  const [totalIncVat, setTotalIncVat] = useState(0);
  const [totalExVat, setTotalExVat] = useState(0);

  // Funkce pro získání začátku a konce aktuálního roku
  const getCurrentYearRange = () => {
    const startOfYear = dayjs().startOf("year");
    const endOfYear = dayjs().endOf("year");
    return [startOfYear, endOfYear];
  };

  // Aktualizace součtů při změně filteredData
  useEffect(() => {
    const totalEx = filteredData.reduce(
      (acc, curr) => acc + parseFloat(curr.totalExVat || 0),
      0
    );
    const totalInc = filteredData.reduce(
      (acc, curr) => acc + parseFloat(curr.totalIncVat || 0),
      0
    );
    setTotalExVat(totalEx.toFixed(2));
    setTotalIncVat(totalInc.toFixed(2));
  }, [filteredData]);

  const handleStatusChange = async (key, newStatus) => {
    try {
      const response = await callApi("invoices/updatePaymentStatus", "PUT", {
        invoiceId: key,
        newStatus: newStatus,
      });

      if (!response.error) {
        const newData = filteredData.map((item) =>
          item.key === key ? { ...item, status: newStatus } : item
        );
        setFilteredData(newData);
        setOpenPopover(null);
        message.success("Payment status updated successfully");
        refreshInvoices();
      } else {
        throw new Error(response.message || "Failed to update payment status");
      }
    } catch (error) {
      console.error("Error updating payment status:", error);
      message.error(error.message || "Failed to update payment status");
    }
  };

  const handleDateRangeChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      const filtered = data.filter((item) => {
        const itemDate = dayjs(item.invoiceDate);
        return itemDate.isBetween(start, end, null, "[]");
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

  const handleSupplierSearch = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") {
      setFilteredData(data);
    } else {
      const filteredData = data.filter((item) =>
        item.supplier.toLowerCase().includes(lowercasedValue)
      );
      setFilteredData(filteredData);
    }
  };

  const handleDownload = async (record) => {
    if (!record || !record.key || !record.invoiceNumber || !record.supplier) {
      console.error("Invalid record data:", record);
      message.error("Unable to download invoice. Missing invoice data.");
      return;
    }

    try {
      console.log("Attempting to download invoice:", record);
      const response = await fetch(
        `https://api.lootea.cz/api/invoices/download/${record.key}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Add any other necessary headers, like authorization
          },
        }
      );

      if (response.ok) {
        // Create a custom filename
        const sanitizedSupplierName = removeDiacritics(record.supplier)
          .replace(/[^a-z0-9]/gi, "_")
          .toLowerCase();
        const filename = `${record.invoiceNumber}_${sanitizedSupplierName}.pdf`;

        // Create a blob from the response
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        message.success(`Invoice '${filename}' downloaded successfully`);
      } else {
        const errorText = await response.text();
        console.error(
          "Server responded with an error:",
          response.status,
          errorText
        );
        throw new Error(
          `Failed to download invoice. Server responded with ${response.status}`
        );
      }
    } catch (error) {
      console.error("Error downloading invoice:", error);
      message.error(`Failed to download invoice: ${error.message}`);
    }
  };

  const handleView = async (record) => {
    if (!record || !record.key) {
      console.error("Invalid record data:", record);
      message.error("Unable to view invoice. Missing invoice data.");
      return;
    }

    try {
      const response = await fetch(
        `https://api.lootea.cz/api/invoices/download/${record.key}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Add any other necessary headers, like authorization
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        setCurrentPdfUrl(url);
        setViewModalVisible(true);
      } else {
        throw new Error(
          `Failed to fetch invoice. Server responded with ${response.status}`
        );
      }
    } catch (error) {
      console.error("Error viewing invoice:", error);
      message.error(`Failed to view invoice: ${error.message}`);
    }
  };

  const handlePrint = async (record) => {
    if (!record || !record.key) {
      console.error("Invalid record data:", record);
      message.error("Unable to print invoice. Missing invoice data.");
      return;
    }

    try {
      const response = await fetch(
        `https://api.lootea.cz/api/invoices/download/${record.key}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Add any other necessary headers, like authorization
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        // Create a hidden iframe
        const printFrame = document.createElement("iframe");
        printFrame.style.display = "none";
        document.body.appendChild(printFrame);

        printFrame.src = url;

        printFrame.onload = () => {
          try {
            printFrame.contentWindow.print();
          } catch (printError) {
            console.error("Error while printing:", printError);
            message.error("Failed to print. Please try again.");
          } finally {
            // Clean up
            URL.revokeObjectURL(url);
          }
        };
      } else {
        throw new Error(
          `Failed to fetch invoice for printing. Server responded with ${response.status}`
        );
      }
    } catch (error) {
      console.error("Error printing invoice:", error);
      message.error(`Failed to print invoice: ${error.message}`);
    }
  };

  const pagination = {
    pageSize: pageSize,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
    showTotal: (total, range) =>
      `Zobrazeno ${range[0]}-${range[1]} z ${total} faktur`,
    onShowSizeChange: (current, size) => {
      setPageSize(size);
    },
  };

  const columns = [
    {
      title: "Faktura",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
    },
    {
      title: "Typ",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        if (text === "Invoice") return "Faktura";
        if (text === "Receipt") return "Účtenka";
        return text; // Pro jistotu vrátí původní text, pokud není ani Invoice ani Receipt
      },
    },
    {
      title: "Datum vystavení",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      sorter: (a, b) =>
        dayjs(a.invoiceDate).unix() - dayjs(b.invoiceDate).unix(),
      render: (date) =>
        dayjs(date).isValid() ? dayjs(date).format("DD. MM. YYYY") : date,
    },
    {
      title: "Datum zdanitelného plnění",
      dataIndex: "taxableDate",
      key: "taxableDate",
      sorter: (a, b) =>
        dayjs(a.taxableDate).unix() - dayjs(b.taxableDate).unix(),
      render: (date) =>
        dayjs(date).isValid() ? dayjs(date).format("DD. MM. YYYY") : date,
    },
    {
      title: "Datum splatnosti",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => dayjs(a.dueDate).unix() - dayjs(b.dueDate).unix(),
      render: (date) =>
        dayjs(date).isValid() ? dayjs(date).format("DD. MM. YYYY") : date,
    },

    {
      title: "Dodavatel",
      dataIndex: "supplier",
      key: "supplier",
      render: (text, record) => (
        <div>
          {text}
          <br />
          <span className="email">{record.email}</span>
        </div>
      ),
    },
    {
      title: "Způsob platby",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      filters: [
        { text: "Platební karta", value: "Credit Card" },
        { text: "Bankovní převod", value: "Bank Transfer" },
        { text: "Hotovost", value: "Cash" },
        { text: "Jiné", value: "Other" },
      ],
      onFilter: (value, record) => record.paymentMethod === value,
      render: (text) => text || "Jiné",
    },

    {
      title: "Celkem (bez DPH)",
      dataIndex: "totalExVat",
      key: "totalExVat",
      render: (value) => (value ? `${parseFloat(value).toFixed(2)}` : value),
    },
    {
      title: "Celkem (s DPH)",
      dataIndex: "totalIncVat",
      key: "totalIncVat",
      render: (value) => (value ? `${parseFloat(value).toFixed(2)}` : value),
    },

    {
      title: "Stav",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Zaplaceno", value: "Paid" },
        { text: "Nezaplaceno", value: "Unpaid" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (text, record) => (
        <Popover
          content={
            <Space>
              <Button
                onClick={() => handleStatusChange(record.key, "Paid")}
                type={text === "Paid" ? "primary" : "default"}
              >
                Zaplaceno
              </Button>
              <Button
                onClick={() => handleStatusChange(record.key, "Unpaid")}
                type={text === "Unpaid" ? "primary" : "default"}
              >
                Nezaplaceno
              </Button>
            </Space>
          }
          trigger="click"
          open={openPopover === record.key}
          onOpenChange={(visible) => {
            if (visible) {
              setOpenPopover(record.key);
            } else {
              setOpenPopover(null);
            }
          }}
        >
          <span className={`status ${text.toLowerCase()}`}>
            {text === "Paid" ? "Zaplaceno" : "Nezaplaceno"}
          </span>
        </Popover>
      ),
    },
    {
      title: "Akce",
      key: "actions",
      render: (text, record) => (
        <span>
          <DownloadOutlined
            className="action-icon"
            onClick={() => {
              console.log("Download clicked for record:", record);
              handleDownload(record);
            }}
          />
          <EyeOutlined
            className="action-icon"
            onClick={() => handleView(record)}
          />
          <PrinterOutlined
            className="action-icon"
            onClick={() => handlePrint(record)}
          />
        </span>
      ),
    },
  ];
  return (
    <div className="invoice-table">
      <h1>Faktury</h1>
      <Space direction="vertical" size="middle" style={{ marginBottom: 16 }}>
        <Space>
          <Input
            placeholder="Vyhledat dodavatele..."
            onChange={(e) => handleSupplierSearch(e.target.value)}
            style={{ width: 300 }}
          />
          <RangePicker
            onChange={handleDateRangeChange}
            placeholder={["Počáteční datum", "Koncové datum"]}
            locale={locale}
          />
        </Space>
      </Space>
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={pagination}
        loading={loading}
        rowKey="key"
        footer={() => (
          <div className="invoice-footer">
            <div>
              <strong>Celkem bez DPH:</strong> {totalExVat} Kč
            </div>
            <div>
              <strong>Celkem včetně DPH:</strong> {totalIncVat} Kč
            </div>
          </div>
        )}
      />

      <Modal
        title="Náhled faktury"
        open={viewModalVisible}
        onCancel={() => {
          setViewModalVisible(false);
          setCurrentPdfUrl(null);
        }}
        width={800}
        footer={null}
      >
        {currentPdfUrl && (
          <iframe
            src={currentPdfUrl}
            width="100%"
            height="1000vh"
            title="Invoice PDF Viewer"
          />
        )}
      </Modal>
    </div>
  );
};

export default InvoiceTable;
